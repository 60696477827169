import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Faqs from "../components/Common/Faqs"
import Hero from "../components/Contact/Hero"
import SEO from "../components/App/SEO"
import ClientFeedback from "../components/Index/ClientFeedback"


const Contact = () => {
  return (
    <Layout>
      <SEO title="Get in touch with SiteBunker - Your hosting and domain provider"
          description="More than 4500 clients chose SiteBunker’s hosting services.Since 2006 we offer premium web hosting services, domains and VPS servers. 24/7 technical support."
      />
      <Navbar />
      <Hero />
      <Faqs />
      <ClientFeedback
      title={"Find out why our clients choose"}
      bold={"SiteBunker hosting services!"}/>
      <Footer />
    </Layout>
  )
}

export default Contact
