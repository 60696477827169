import React from "react"
import ContactForm from "./ContactForm"
import heroBg from "/src/assets/images/contact/contact-hero.png"
import ContactSvg from "../../assets/svgComponents/contact.svg"


const Hero = () => {
  return (
    <div>
      <div className="contact-hero" style={{ backgroundImage: `url(${heroBg})` }}>
        <div className="container">
          <div className="page-title-content text-left position-relative">
            <ContactSvg className="contact-svg" />
            <div className="z-index-pos">
              <h1 className="mb-4 main-color">Contact SiteBunker</h1>
              <div className="row">
                <div className="col-md-10 col-12">
                  <p className="mb-5">At SiteBunker we are at your disposal no matter the question and we offer you the
                    best solutions for any technical challenge. Get in touch with us.</p>
                </div>
              </div>
              <h1 className="main-color mb-4">SiteBunker Customer Support Team</h1>
              <div className="row">
                <div className="col-md-10 col-12">
                  <p>Enjoy the technical support provided 24/7 by SiteBunker. We use a high-performance ticketing system
                    which can be accessed in just a few clicks. </p>
                  <a href="https://client.sitebunker.net/submitticket.php" className="form-btn mt-3 d-inline-block">OPEN
                    A TICKET</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  )
}

export default Hero