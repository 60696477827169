import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion"

import "/src/assets/css/Common/faqs.css"
import { Link } from "gatsby"

const Faqs = () => {
  return (
    <div className="ptb-100">
      <div className="container">
        <h1 className="main-color mb-4">Frequently asked questions</h1>
        <div className="faq-accordion">
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is web hosting?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  SiteBunker hosting subscriptions allow you to publish your site on the internet. You will need a web
                  hosting service to go live.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Do I need technical background to be able to use hosting?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  SiteBunker hosting services are accessible to everyone, whether you are on your first project or an
                  expert. Our control panel is intuitive and helps you to easily manage all domains and hosting
                  packages.
                  In addition, our customer support team is available by phone and by online ticket to assist you, no
                  matter the technical challenge you face.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How long does it take to set up a web hosting package?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Setting up web hosting is quick and easy. It takes up to 5 -10 minutes to configure the web hosting
                  package ordered from SiteBunker.
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Do I need a domain name from SiteBunker to be able to buy web hosting?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  No, you can have your own domain, which can be transferred to SiteBunker. Keep in mind that you can
                  also buy new domains directly from <a
                  href="https://client.sitebunker.net/cart.php?a=add&domain=register&language=english&currency=3"
                  target="_blank">SiteBunker.</a>
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is the difference between Linux and Windows web hosting?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Linux and Windows are two different operating systems. The operating system you use on your computer
                  should not determine your hosting choice. For example, if your computer is running on Microsoft
                  Windows, Linux hosting will work just as fine as Windows hosting. The choice between Linux and Windows
                  web hosting is up to you. If you want to use PHP, MySQL or Perl, alongside a large number of other
                  software programs, Linux is recommended for you. However, if you want to include searchable databases
                  and Windows-specific applications, such as ASP, then we advise you to choose Windows web hosting.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I subscribe as a SiteBunker hosting reseller?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  At SiteBunker, every hosting reseller enjoys a reliable partnership. Because your success is also our
                  success, we offer you a wide range of complete hosting services with premium resources included at
                  accessible costs. Find out more details <Link to="/reseller-ssd-hosting/">here.</Link>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I subscribe to SiteBunker affiliate program?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The SiteBunker affiliate program is dedicated for hosting packages, including dedicated servers and
                  VPSs. You can enjoy 15% commission for every recurring sale generated by you. Apply <Link
                  to="/affiliates/">here to the SiteBunker affiliate</Link>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can I have a dedicated IP?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  At SiteBunker you can benefit from a dedicated IP in just a few simple steps. Contact our Customer
                  Support team and you will be efficiently assisted for a fast&safe configuration.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Can I have my own hosting server?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  Now it's easy to rent your own hosting server. Find out the included benefits for dedicated hosting
                  server subscriptions <Link to="/dedicated-servers/">here.</Link>
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Faqs
